import React from 'react'
import { SectionWrapper } from '../../hoc'

function Member() {
  return (
    <div>
      <h1>Member</h1>
    </div>
  )
}

export default SectionWrapper(Member,'member');
